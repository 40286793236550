// @tailwind base;
// @tailwind components;
// @tailwind utilities;

//uitility classes
.border-left {
  border-left: 2px solid #00a84d;
  &.active {
    border-left: 4px solid #00a84d;
  }
}

.border-right {
  border-right: 2px solid #00a84d;
  &.active {
    border-right: 4px solid #00a84d;
  }
}
.border-top {
  border-top: 2px solid #00a84d;
}
.border-bottom {
  border-bottom: 2px solid #00a84d !important;
  &.active {
    border-bottom: 4px solid #00a84d;
  }
}

.table-head-active {
  background: #00a84d;
  color: #fff !important;
  &:hover {
    background: #00a44d;
  }
}

.table-cell-active-bg {
  background: #0bbc5b1e;
}

.w-740px {
  // width: 740px
  width: 800px;
}

.login-banner {
  p {
    list-style: none;
    display: flex;
    position: relative;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
    padding-right: 15px;
    width: 500px;
  }
}
a {
  color: #1570ef;
  &:hover {
    color: #0e61d5;
  }
}
.form-heading {
  font-size: 27px;
  text-align: left;
}

// .menu-link-wrapper {
//   overflow-y: scroll;
//   height: 100%;
// }

.sub-main-menu {
  .menu-link {
    padding: 0.2rem 2.5rem;
    font-size: 12px !important;
    &:hover {
      .menu-title {
        display: block;
        position: relative;
        &::after {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          border-bottom: 0.5px solid #fff;
        }
      }
      .menu-bullet {
        .bullet {
          background: #fff !important;
        }
      }
    }
    .menu-title,
    .menu-bullet {
      color: #fff !important;
      font-weight: 400;
    }
  }
  &.here.show {
    .menu-title {
      border-bottom: 0.5px solid #fff;
    }
  }
}

//aside overflow scroll & custom scrollbar

.asaside-nav {
  overflow-y: scroll;
  padding-bottom: 80px;
  height: 100%;
  #kt_aside_menu {
    padding-bottom: 60px;
  }
}

div {
  scrollbar-width: none;
}

//company info form modal

.company-info-form {
  z-index: 45;
  padding: 2rem;
  border-radius: 20px;
  max-width: 1200px;
}

:root {
  font-size: 16px;
}

html {
  font-size: 16px;
}

input:focus,
textarea:focus {
  border: 1.5px solid #00a84d !important;
}

select:focus {
  border: 1.5px solid #00a84d !important;
}

textarea {
  resize: none;
}

label {
  font-size: 1.25rem !important;
}

.tab-menu-div > span {
  background: #e8e8e8;
  width: 100%;
  height: 50px;
  text-align: center;
  margin: 2px;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: #c6e8d5;
  }
}

.tab-menu-date {
  font-size: 16px;
  color: #00a84d;
  font-weight: 400;
}

.tab-menu-first {
  border-radius: 40px 0 0 40px;
}

.tab-menu-last {
  border-radius: 0 40px 40px 0;
}

.tab-processing {
  background: #c6e8d5 !important;
}

.tab-completed {
  background: #00a84d !important;
  color: #fff !important;
}

.calculator-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f4f4f4;
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 10px;
}

.calculator-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.small-text {
  font-size: 1rem;
  font-weight: normal;
}

.divider {
  border-bottom: 1px solid #00000033;
}

.results-card {
  background: #0bbc5c10;
  box-shadow: none;
  .calculator-header {
    .result-heading > h3 {
      color: #093624;
    }
  }
}

.textarea__counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  pointer-events: none;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px;
}

.textarea__counter--outer {
  position: absolute;
  bottom: 0px;
  right: 10px;
  pointer-events: none;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px;
}

.textarea_error_message {
  color: red;
  font-weight: 500;
}

// question-modal

.custom-modal {
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 40px;
  text-align: center;
}

.modal-heading {
  margin-bottom: 3rem;
}

.modal-content {
  border-radius: 20px;
}

.aside .menu > .menu-item > .menu-link:hover .menu-icon {
  background-color: transparent;
}

//custom-buttons

.main-btn {
  background: linear-gradient(90.48deg, #00a84d -12.26%, #262626 180.27%);
  border-radius: 8px;
  padding: 16px;
  border-style: none;
  color: #fff;
  font-weight: Medium;
  transition: all 0.1s !important;

  &:hover {
    color: #fff;
    // background: linear-gradient(180deg, #00a84d -12.26%, #262626 180.27%);
    transform: scale(1.02);
    &:active {
      color: #fff !important;
    }
  }
}

.outline-btn {
  border: 2px solid #093624 !important;
  border-radius: 8px;
  padding: 16px;
  font-weight: Medium;
  color: #093624;
  transition: all 0.1s !important;

  &:hover {
    border: 2px solid #0a5a3a !important;
    color: #0a5a3a;
    // transform: translateY(-3px);
    transform: scale(1.02);
  }
}

.outline-btn-danger {
  border: 2px solid #c82333 !important;
  border-radius: 8px;
  padding: 16px;
  font-weight: Medium;
  color: #c82333;
  transition: all 0.1s !important;

  &:hover {
    border: 2px solid #dc3545 !important;
    color: #ffffff;
    background-color: #c82333;
    // transform: translateY(-3px);
    transform: scale(1.02);
  }
}

//summary

.summary-header {
  background: #e8e8e8;
  padding: 1rem;
  border-radius: 6px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .summary-heading {
    margin-bottom: 0;
  }
}

.barContainer {
  min-height: 300px;
}

//choose industry modal

.choose-industry-btn {
  border: 1px solid #c1c1c1 !important;
  border-radius: 8px;
  padding: 16px;
  font-weight: Medium;
  text-align: left;
  color: #404040;
  transition: all 0.1s !important;
  label {
    flex-grow: 1;
    transition: all 0.1s !important;
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    border: 1px solid #00a84d !important;
    color: #111111;
    cursor: pointer;
    // transform: translateY(-3px);
    // transform: scale(1.02);
  }
}

// input[type='radio'] {
//   background-color: #0a5a3a;
// }

input[type='radio'] {
  accent-color: #242424;
  width: 20px;
  height: 20px;
  margin: 8px 8px 0 0;
  background-color: #becec7;
}

.sub-total-container {
  border: 2px solid #00a84d;
  border-radius: 10px;
  .sub-text {
    color: #093624;
    font-size: 15px;
    font-weight: 300;
    margin-left: 4px;
  }
}

//collapsible table

.tableStripColor {
  background-color: #d9d9d950;
}

// .Mui-focused {
//   border-color: #00a84d !important;
// }

.custom-scrollbar-div {
  scrollbar-width: thin;
  // scrollbar-color: pink lightblue;
  scrollbar-color: auto;
}

.list-table-container {
  background: #cbcbcb;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 10px;

  .list-table {
    table-layout: fixed;
    width: 100%;
    thead {
      background: #fff;
    }
    td,
    th {
      padding: 6px 8px;
    }
    th {
      padding: 12px 8px;
    }
  }
}

.bar-chart-container {
  background: transparent;
  border-radius: 10px;
  border: 1px solid black;
}

.fv-plugins-message-container {
  .form-error {
    color: #ff464b;
    font-size: 0.95em;
    font-weight: 500;
  }
}

//offset

//offset carbon tab menu

.tab-menu-heading-select-option {
  border: 0;
  outline: 0;
  background: transparent;
  color: #00a84d;
  &:focus {
    outline: 0;
    border: 0 !important;
  }
}

.caluculator-card-offset {
  background: transparent;
  box-shadow: none;
}

//offset table

#outlined-adornment-weight:focus {
  border: none !important;
}

.bar-chart-div {
  max-width: 800px;
  width: 100%;
  max-height: 500px;
  height: 100%;
}

.small-text-heading {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 15px;
}

.carbon-capacity-rec {
  font-weight: 500;
  font-size: 1.3rem;
  background: #0bbc5c10;
  border-radius: 10px;
  padding: 8px 20px;
  label {
    font-size: 1.5rem;
  }
}

//offset carbon summary

.card-info {
  background: rgba(11, 188, 92, 0.05);
  border-radius: 10px;
  flex-grow: 1;
  margin-right: 1rem;
}

.left-side-container {
  border-right: 1px solid rgba(198, 232, 213, 1);
}

.summary-table-container {
  margin-right: 1rem;
}

.bordered-div {
  border: 1px solid #000;
  border-radius: 10px;
  height: 280px;
}

//sub-menu dropdown custom styling

//media-query

@media all and (max-width: 576px) {
  html {
    font-size: 16px;
  }
  .tab-menu-div > span {
    height: 50px;
    margin: 0.5px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 2px;
  }
  .tab-menu-date {
    font-size: 12px;
  }
  .bottom-buttons {
    flex-direction: column;
    button {
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }
  }
  .custom-modal-width {
    min-width: 90vw !important;
  }
}

@media all and (max-width: 768px) {
  .custom-modal {
    padding: 20px 20px;
  }
}

@media all and (max-width: 1400px) {
  .upper-inputs,
  .bottom-input {
    flex-direction: column;
  }
}

// Food Calculator ui

.btn-option-selected {
  background: #000;
  border-radius: 6px;
  color: #fff;
  padding: 9px 20px;
  border: none;
  &:hover {
    background: #282828;
  }
}

.btn-option {
  border: 1.4px solid #000;
  border-radius: 6px;
  padding: 8px 20px;
  &:hover {
    background: #e2e2e2;
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.no-of-containers {
  .MuiOutlinedInput-root {
    padding: 4px !important;
  }
}

.edit-btn {
  cursor: default;
  color: #555;

  &.active {
    color: #000;
    cursor: pointer;
  }
}

.pie-bottom-label {
  position: absolute;
  bottom: 82px;
  // top: 293px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.pap-container {
  border: 2px solid #00a84d;
  padding: 0px;
  &.active {
    background: rgba(11, 188, 91, 0.118);
    border: 4px solid #00a84d;

    .pap-head {
      color: #fff;
      background-color: #00a84d;
    }
  }
  .pap-head {
    // border: 2px solid #00a84d;
    display: block;

    padding: 10px 0px;
    text-align: center;
    font-size: 1.225rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.1s !important;
    &.active {
      color: #fff;
      background-color: #00a84d;
    }
    &:hover {
      color: #fff;
      background-color: #019245;
    }
    &.disabled {
      cursor: default;
    }
  }
  .pap-body {
    border-top: 2px solid #00a84d;
    padding: 25px;

    // margin-bottom: 20px;
    // height: auto;
    &.active {
      background: rgba(11, 188, 91, 0.118);
      border-bottom: 4px solid #00a84d;
      border-left: 4px solid #00a84d;
      border-right: 4px solid #00a84d;
    }
    .no-of-containers {
      margin-bottom: 5px;
    }
  }
}

/* .pap-head {
  border: 2px solid #00a84d;

  padding: 10px 0px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s !important;
  &.active {
    color: #fff;
    background-color: #00a84d;
  }
  &:hover {
    color: #fff;
    background-color: #019245;
  }
}
.pap-body {
  padding: 25px;
  border-bottom: 2px solid #00a84d;
  border-left: 2px solid #00a84d;
  border-right: 2px solid #00a84d;
  margin-bottom: 20px;
  height: auto;
  &.active {
    background: rgba(11, 188, 91, 0.118);
    border-bottom: 4px solid #00a84d;
    border-left: 4px solid #00a84d;
    border-right: 4px solid #00a84d;
  }
  .no-of-containers {
    margin-bottom: 5px;
  }
} */
