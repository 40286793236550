@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
:root {
  --color-logo: #000fd0;
}
.sortable th {
  position: relative;
  float: left;
}
.sortable th:before {
  content: '';
  position: absolute;
  right: -26px;
  bottom: 0;
  background-image: url(../public/media/ePulse/icons/sort_default.svg);
  /* float: right; */
  width: 20px;
  height: 20px;
  background-size: 20px;
  opacity: 0.15;
}
.sortable.asc th:before {
  background-image: url(../public/media/ePulse/icons/sort_asc.svg);
  opacity: 0.5;
}
.sortable.desc th:before {
  background-image: url(../public/media/ePulse/icons/sort_desc.svg);
  opacity: 0.5;
}

/* CSS FOR APP START */
.wrapper {
  font-family: 'Montserrat', sans-serif;
}
.wrapper h2.page-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.header {
  z-index: 95 !important;
}
.sidebar {
  height: 100%;
  width: 88px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  white-space: nowrap;
  transition: all 0.5s !important;
}
.sidebar.expanded {
  width: 220px;
}
.sidebar + .wrapper {
  transition: all 0.5s !important;
}
.sidebar.expanded + .wrapper {
  padding-left: 220px;
}
.sidebar + .wrapper .header {
  transition: all 0.5s !important;
}
.sidebar.expanded + .wrapper .header {
  left: 220px;
}
.toggle-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  right: -12px;
  top: 61px;
  transition: all 0.5s !important;
}
.sidebar.expanded .toggle-icon {
  transform: rotate(180deg);
}
.sidebar.expanded .menu-item {
  font-size: 14px;
}
.asaside-nav .menu-item:hover .menu-link .menu-item,
.asaside-nav .menu-item.show .menu-link .menu-item {
  color: 3590A7;
}
.sidebar .menu-item .menu-link {
  font-size: 14px;
  justify-content: flex-start;
  max-width: 65px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
}
.sidebar .menu-item .menu-link .menu-icon {
  padding-left: 40px !important;
}
.sidebar .menu-item .menu-link .menu-text {
  margin-left: 10px;
  opacity: 0;
  transition: all 0.5s !important;
}
.sidebar.expanded .menu-item .menu-link .menu-text {
  opacity: 1;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
  margin-left: 85px;
}

.aside-logo {
  position: relative;
}

/* .aside-logo:after {
  content: ' ';
  position: absolute;
  left: 25%;
  bottom: -3px;
  width: 50%;
  height: 1px;
  background-color: #fff;
  opacity: 0.3;
} */
.asaside-nav .menu-link img {
  min-width: 24px;
}
.asaside-nav .menu-item:hover,
.asaside-nav .menu-item.show {
  /* background: #fbfcfd; */
  color: #fff;
}

.asaside-nav .menu-item:hover .menu-text,
.asaside-nav .menu-item.show .menu-text {
  border-bottom: #00a84d 1px solid;
  font-weight: bold;
}
/* .asaside-nav .menu-item:hover .menu-link img,
.asaside-nav .menu-item.show .menu-link img {
  filter: invert(53%) sepia(10%) saturate(2771%) hue-rotate(145deg) brightness(90%) contrast(79%);
} */

.aside .menu > .menu-item.here > .menu-link .menu-icon,
.aside .menu > .menu-item.show > .menu-link .menu-icon {
  background-color: rgba(255, 255, 255, 0);
}

.asaside-nav .menu-sub-dropdown {
  border-radius: 0;
  box-shadow: none;
  padding: 0 !important;
}
.asaside-nav .menu-sub-dropdown .menu-item {
  background: #fbfcfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #eff3f5;
  padding: 0.5rem 0;
}
.menu-sub-dropdown .menu-item a span {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.asaside-nav .menu-sub-dropdown .menu-item:hover,
.asaside-nav .menu-sub-dropdown .menu-item.show {
  background: #eff1f4;
  color: #0e0e0e;
}
.asaside-nav .menu-sub-dropdown .menu-item,
.asaside-nav .menu-sub-dropdown .menu-item a,
.asaside-nav .menu-sub-dropdown .menu-item a .menu-title {
  color: #0e0e0e !important;
}

.header-fixed .header {
  background: #fff;
  min-height: 75px;
}
.user-badge-block {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.user-badge-block .user-avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.75rem;
}
.user-badge-block .user-avatar img {
  max-width: 100%;
  max-height: 100%;
}
.user-badge-block .user-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #1f1f1f;
  line-height: 1.2;
}
.user-badge-block .user-role {
  color: #cdcdcd;
  font-size: 10px;
  font-weight: 500;
}
.page-title h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #4e4e55;
  margin-bottom: 0 !important;
}
.page-title .breadcrumb,
.page-title .breadcrumb .breadcrumb-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #838383;
  margin-top: 0 !important;
}
.page-title .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: #838383;
  padding-right: 5px;
}
.page-header {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.searchbox-wrapper {
  position: relative;
}
.searchbox-wrapper .serachbtn-wrapper {
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  background: #fff;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #ddd;
}
.searchbox-wrapper .serachbtn-wrapper img {
  width: 20px;
}

.filter-row-notification {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: end;
}

.filter-row {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}
.filter-row > * {
  margin: 0 8px;
}
.filter-row select {
  max-width: 170px;
  max-height: 38px;
  /* margin-right: 20px; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0.65rem 1rem;
}
.filter-row input {
  max-width: 230px;
  max-height: 38px;
  padding: 0.65rem 1rem;
  /* margin-right: 20px; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
}
.filter-row .filter-head {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}
.filter-row span.filter-action {
  color: #398aa6;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin: 0 15px;
}
.filter-row button {
  margin-left: auto;
  height: 37px !important;
  font-weight: 600;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 10px; /* Adjust the gap between items as needed */
}

.list-item {
  padding: 10px;
}

/* VERTICALTABS START */

.horizontal-tab-list {
  display: flex;
  flex-direction: row;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 0.5rem;
}
.tab-list {
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

/* VAERTICALTABEND */

.modalcontainer {
  position: relative;
}

.modalCalendar {
  position: absolute;
  top: 100%; /* position modal just below the div */
  right: 0;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 1000;
}

.ePulse-roundedCorner {
  /* border-top-right-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
  /* background-color: var(--color-logo); */
  /* background: linear-gradient(184.94deg, #29aaa9 -6.13%, #564ea2 68.99%); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #161717, #124e2e);
  /* background: linear-gradient(180deg, #161717, #0bbc5c); */
}

.ePulse-datePickerParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.ePulse-datePicker {
  width: 80px;
  height: 30px;
  border: 0;
}

.ePulse-borderBottom-light {
  border-bottom-color: gray;
}

.ePulse-border-light-grey {
  border: 1px solid #979797;
}

.ePulse-border-light {
  border: 1px solid #c3c0c0;
}

.ePulse-border-top-light {
  border-top: 1px solid #c3c0c0;
}

.ePulse-border-bottom-light {
  border-bottom: 1px solid #c3c0c0;
}

.ePulse-border-primary {
  border: 1px solid var(--color-logo);
}

.ePulse-appColor-primary {
  /* background-color: var(--color-logo); */
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%);
}

.ePulse-Gradient-Color-button {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%);
  border-radius: 20px;
}

.checkbutton {
  background-color: #eaeaea;
  /* color: white; */
}

.checkbutton:checked {
  background-color: #398aa6;
  border-color: #797979;
}

.ePulse-textColor-primary {
  /* color: linear-gradient(184.94deg, #29aaa9 -6.13%, #564ea2 68.99%) !important; */
  color: var(--color-logo) !important;
}

.ePulse-bg-light {
  background-color: #ece6f6 !important;
}

.ePulse-bg-muted {
  background-color: #eaeaea;
}

.ePulse-modal-90w {
  width: 90vw;
  max-width: 90vw;
}
.ePulse-modal-70w {
  width: 70vw;
  max-width: 70vw;
}

.ePulse-modal-50w {
  width: 50vw;
  max-width: 50vw;
}

.ePulse-modal-30w {
  width: 30vw;
  max-width: 30vw;
}

.ePulse-BG-LightBlue {
  background-color: #e9efff;
  /* background-color: '#EFF1FE'; */
}

.ePulse-BG-Outlet {
  background-color: '#EFF1FE';
}

.ePulse-divider {
  /* border-bottom: 1px solid rgb(212 212 205 / 50%); */
  border-bottom: none;
}
.ePulse-pagination-button {
  /* background-color: #564ea1; */
  /* padding: 3pt; */
  /* align-items: center; */
  /* border-radius: 5pt; */
  /* border: 0; */
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: #fff;
}
.ePulse-pagination-button img {
  margin: 5px 8px;
  width: 16px;
  height: 16px;
}

.ePulse-pagination-dropdown {
  height: 30px;
  /* border-radius: 5pt; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0 10px;
}

.ePulse-button {
  height: '30px';
  color: #ffffff;
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  border: 0;
  padding-left: 12pt;
  padding-right: 12pt;
  border-radius: 10pt;
}

.ePulse-button-secondory {
  height: '30px';
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

.ePulse-button-disabled {
  height: '30px';
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #c3c0c0;
}

.custom-pagination .page-item.active .page-link {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  /* background-color: var(--color-logo) !important;
  border-color: var(--color-logo) !important; */
}

.custom-pagination .page-link .page-item.active {
  color: #ffffff !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .my-custom-class td:nth-child(1) {
  width: '80%';
}

.my-custom-class td:nth-child(2) {
  width: 200px;
} */

/* CSS FOR APP END */

/* CSS FOR REACT-CALENDAR START */

.react-calendar {
  width: 440px;
  max-width: 100%;
  height: 320px;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 10px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 24px; /* updated 44 to 24*/
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.component-card {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  height: 5rem;
  width: 17rem;
  background-color: #e6e6e6;
  align-items: center;
  justify-content: center;

  /* d-flex align-items-center justify-content-center col-sm-3 h-50px shadow-sm m-1 */
}
.component-card.active {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  height: 5rem;
  width: 17rem;
  background-color: #bcbcbc;
  align-items: center;
  justify-content: center;
  /* d-flex align-items-center justify-content-center col-sm-3 h-50px shadow-sm m-1 */
}

/* CSS FOR REACT-CALENDAR END */

/* Custom CSS by KW */

.login-section {
  font-family: 'Montserrat', sans-serif;
}

.login-section .form-group {
  position: relative;
  border: none;
  margin-bottom: 1.5rem;
  padding: 0;
}
.login-section .form-group .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: var(--white, #fff);
  padding: 0 5px;
}
.login-section .form-group .form-control {
  border-radius: 5px;
  border: 1px solid var(--light-grey, #d3d3d3);
  background: var(--white, #fff);
}
.login-section .form-error {
  color: #ff464b;
  font-size: 0.95em;
  font-weight: 500;
}
.login-section button {
  border-radius: 7px;
  background: #00a84d;
  color: var(--white, #fff) !important;
  width: 100%;
}
.login-section button:hover {
  color: var(--white, #fff);
  background: #019546;
  /* text-decoration: underline; */
}
.login-section .btn:disabled {
  color: var(--white, #fff);
  background: #019546;
  /* text-decoration: underline; */
}
.login-section .login-footer a {
  margin-right: 1rem;
  /* padding-right: 1rem; */
  position: relative;
  /* border-right: 1px solid #eee; */
}
/* .login-section .login-footer a::after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 4px;
  height: 12px;
  width: 1px;
  background-color: #ccc;
} */
.login-section .login-footer a:last-child:after {
  display: none;
}
.login-banner {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-banner h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 3.08px;
  /* text-transform: uppercase; */
}
.login-banner ul {
  list-style: none;
  display: flex;
  /* border-top: 1px solid #fff; */
  position: relative;
  margin-top: 10px;
}
.login-banner ul::before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  height: 1px;
  width: 100%;
  background-color: #fff;
  opacity: 0.8;
}
.login-banner ul li {
  margin-right: 1rem;
  padding-right: 1rem;
  position: relative;
  /* border-right: 1px solid #eee; */
}
.login-banner ul li::after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 4px;
  height: 12px;
  width: 1px;
  background-color: #fff;
}
.login-banner ul li:last-child:after {
  display: none;
}
@media all and (max-width: 1024px) {
  .login-banner {
    display: none;
  }
  .login-section,
  .login-section > div {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .login-banner {
    display: none;
  }
  .login-section,
  .login-section > div {
    width: 100%;
  }
}

/* List page CSS updates */
.admin-table thead {
  background-color: #eeeeee;
}
.admin-table thead th {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #4e4e55 !important;
}
.admin-table thead tr > th {
  text-align: left;
  min-width: 100px;
}
.admin-table tbody tr {
  border-bottom: 1px solid #eeeeee;
}
.admin-table tbody td {
  text-align: left;
}
.admin-table tbody td span {
  font-family: 'Roboto', sans-serif;
  font-size: 13px !important;
  color: #6c6c6d !important;
  font-weight: 400 !important;
}
.admin-table tbody td span svg {
  width: 18px !important;
  height: 18px !important;
}
.admin-table .pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-table .pagination .page-show span {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-right: 5px;
}
.admin-table .pagination .page-num {
  display: flex;
  align-items: center;
}

/* Dashboard CSS */
.dashboard-landing .dashboard-counters {
  margin-bottom: 15px;
}
.dashboard-landing .dashboard-counters .count-block {
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
  padding: 5px 15px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}
.dashboard-landing .dashboard-counters .count-block .count-block-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* line-height: 3; */
}
.dashboard-landing .dashboard-counters .count-block .count-block-content .content-name {
  color: #4e4e55;
  font-size: 16px;
  font-weight: normal;
  margin: 0.25rem 0;
  line-height: 1.2;
}
.dashboard-landing .dashboard-counters .count-block .count-block-content .content-count {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  margin: 0.25rem 0;
}
.dashboard-landing .dashboard-counters .count-block:last-child {
  margin-right: 0;
}
.dashboard-landing .dashboard-block {
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
  /* padding: 15px; */
  border-radius: 8px;
  margin-bottom: 1.5rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #ecf0f2;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-left {
  display: flex;
  align-items: center;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-left img {
  max-width: 32px;
}
.dashboard-landing .dashboard-block .dashboard-block-header .block-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .link-view-all {
  font-size: 12px;
  color: #398aa6;
  font-weight: 500;
  margin-left: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-right {
  border-radius: 23px;
  border: 1px solid #398aa6;
  padding: 0.25rem 0.5rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-right select {
  border: none !important;
  outline: none !important;
}
.dashboard-landing .dashboard-block .dashboard-block-body {
  margin: 5px 10px;
  padding: 5px;
  /* overflow: hidden auto; */
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-header,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-header {
  justify-content: flex-start;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-header .block-title,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-header .block-title {
  font-size: 14px;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-body,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-body {
  padding: 5px 0;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-body > div,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-body > div {
  padding: 5px;
}
.dashboard-landing .dashboard-block .dashboard-info-row {
  border-bottom: 1px solid #ecf0f2;
  margin: 0.5rem 0;
  padding-bottom: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-info-row > div {
  display: flex;
  align-items: flex-start;
}
.dashboard-landing .dashboard-block .dashboard-info-row > div img {
  width: 24px;
  height: 24px;
  filter: invert(51%) sepia(11%) saturate(2329%) hue-rotate(149deg) brightness(92%) contrast(81%);
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-title {
  color: #343a40;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  /* margin-left: 0.5rem; */
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-date {
  color: #9babb8;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  /* margin-left: 0.5rem; */
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-time {
  color: #adb5bd;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.5rem;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row {
  cursor: pointer;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row .info-title {
  margin-left: 0;
  color: #398aa6;
  font-weight: 600;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row .view-icon {
  filter: invert(51%) sepia(11%) saturate(2329%) hue-rotate(149deg) brightness(92%) contrast(81%);
  cursor: pointer;
}
.dashboard-table-row {
  display: flex;
  padding: 0.5rem;
  border-bottom: 1px solid #ecf0f2;
}
.dashboard-table-row > div {
  font-size: 0.8em;
  line-height: 1.2;
}
.dashboard-table-row .dashboard-table-icon {
  height: 36px;
  width: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1rem;
}
.dashboard-table-row .col-label {
  font-size: 12px;
}
.dashboard-table-row .col-value {
  font-size: 14px;
  /* font-weight: 600; */
}

.dashboard-table-row-header {
  background-color: #eeeeee;
  margin: 5px 10px;
  padding: 7px;
}
.wrapper hr {
  border-top: 1px solid #ecf0f2;
  opacity: 0.9;
}
.wrapper .vertical-tabs {
  background: #fff;
  display: flex;
  flex-direction: row;
}
.wrapper .vertical-tabs .tab-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.5rem;
  padding: 0 0.5rem;
}
.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.ePulse-modal-content .wrapper {
  padding: 0;
}

.wrapper .vertical-tabs {
  background: #fff;
  display: flex;
  flex-direction: row;
}
.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.wrapper .vertical-tabs .horizontal-tab-list .tab {
  /* margin: 0.5rem; */
}

.wrapper .vertical-tabs .tab-head {
  padding: 10px;
  background-color: #bdbdbd;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 600 !important;
  border: none;
  cursor: pointer;
  margin: 1rem;
  border-radius: 0;
  font-weight: 500;
  background-image: url(../public/media/ePulse/icons/chevron_down.svg);
  background-position: right 10px top 6px;
  background-repeat: no-repeat;
  background-size: 30px;
  display: none;
}
.wrapper .vertical-tabs .tab {
  padding: 10px;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  margin: 0.5rem 0;
  border-radius: 0;
  font-weight: 500;
}
.wrapper .vertical-tabs .horizontal-tab-list .tab {
  /* margin: 0.5rem; */
}
.wrapper .vertical-tabs .tab.active {
  background-color: #bdbdbd;
  color: #ffffff;
}

.wrapper .form-wrapper input,
.wrapper .form-wrapper select,
.wrapper .form-wrapper textarea {
  background-color: rgb(239 241 249 / 60%);
  border: none;
}
.wrapper .form-wrapper .form-block {
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.wrapper .form-wrapper .form-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.ePulse-modal {
  font-family: 'Montserrat', sans-serif;
}
.ePulse-modal .modal-header {
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  font-size: 18px;
  font-weight: 500;
}
.ePulse-modal .modal-header button {
  background-color: #bab9b9;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(241deg) brightness(103%) contrast(103%);
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
}
.ePulse-modal .ePulse-modal-content {
  padding: 1rem;
}
.ePulse-modal .ePulse-modal-content .form-block {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  position: relative;
}
.ePulse-modal .ePulse-modal-content .form-block .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 5px;
  z-index: 2;
}

.ePulse-modal .ePulse-modal-content .form-label {
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 10px;
}

.ePulse-modal .ePulse-modal-content select.form-control,
.ePulse-modal .ePulse-modal-content input.form-control {
  max-height: 40px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.ePulse-modal .ePulse-modal-content input.form-control-body {
  max-height: 40px;
  /* min-height: 60px; */
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.ePulse-modal .ePulse-modal-content input.rmdp-input {
  height: 40px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.ePulse-modal .ePulse-modal-content .rdw-editor-toolbar {
  padding-top: 12px;
  border: 1px solid #e4e6ef;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.ePulse-modal .ePulse-modal-content .rdw-editor-main {
  border-radius: 0 0 0.475rem 0.475rem;
}
.ePulse-modal .ePulse-modal-content .btn-row {
  text-align: end;
}
.ePulse-modal .ePulse-modal-content .btn-row .ePulse-btn-primary {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 0;
  border-radius: 20px;
  color: #fff;
}
.ePulse-modal .ePulse-modal-content .text-danger {
  color: #ff464b;
  font-size: 12px;
  float: right;
  position: relative;
  top: -4px;
}

.btn-row {
  text-align: end;
}

.list-label {
  font-size: 13px;
  font-weight: 700;
  z-index: 2;
}

.label-block {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 1rem;
}

.label-block .form-label {
  font-size: 13px;
  font-weight: 500;
  z-index: 2;
}

.label-form-block {
  display: flex;
  flex-direction: row;
  /* margin: 1rem; */
  /* position: relative; */
}

.ePulse-btn-primary {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 0;
  border-radius: 20px;
  color: #fff;
}

.ePulse-btn-selected {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

.ePulse-btn-secondory {
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

span.tag-wrapper.ReactTags__tag {
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
span.tag-wrapper.ReactTags__tag button {
  margin-left: 8px;
}

.section-edit-rx {
  display: flex;
  flex-direction: column;
}
.edit-rx-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.edit-rx-header h2 {
  color: #343a40;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.edit-rx-header .rx-code {
  color: #121217;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.edit-rx-container {
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 8px;
  position: relative;
  max-height: calc(105vh - 205px);
  overflow: hidden;
}
.edit-rx-container .edit-rx-category-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.5rem;
  padding: 0 0.5rem;
  min-width: 300px;
  height: 520px;
  overflow: hidden auto;
}
.edit-rx-container .edit-rx-category-list .add-rx-category-btn {
  border: 2px solid #398aa6;
  color: #398aa6;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 1rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn {
  font-family: 'Montserrat', sans-serif;
  background: #f1f1f1;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn.active {
  font-family: 'Montserrat', sans-serif;
  background: #bdbdbd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.edit-rx-container .edit-rx-category-list .rx-category-btn:first-child {
  background: #bdbdbd;
  color: #ffffff;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .drag-icon svg {
  min-height: 25px;
  margin: 0 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn:first-child .drag-icon svg {
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(128deg) brightness(106%) contrast(103%);
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-rx-category-title {
  flex: 1;
  margin-left: 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-category-actions {
  display: flex;
  margin-left: auto;
  margin-right: 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-category-actions button {
  background: transparent;
  border: none;
}
.edit-rx-content {
  padding: 1rem;
  max-height: 100%;
  overflow: hidden auto;
}
.edit-rx-content .rx-category {
  margin-bottom: 2.5rem;
  /* border-bottom: 1px solid #E7E7E7; */
}
.edit-rx-content .rx-category .rx-category-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 0.75rem;
  margin-bottom: 0.5rem;
}

.edit-rx-content .rx-category .rx-category-header .rx-category-title {
  color: #4e4e55;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  flex: 1;
  /* margin-bottom: 0.5rem; */
}
.edit-rx-content .rx-category button {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  min-height: 35px;
  border-radius: 20px;
  padding: 0 0.75rem;
}
.edit-rx-content .rx-category .add-category-btn {
  border: 1px solid #398aa6;
  color: #ffffff;
  background: #398aa6;
}
.edit-rx-content .rx-category .add-component-btn {
  border: 1px solid #398aa6;
  color: #398aa6;
  background: #fff;
}
.edit-rx-content .rx-category button:not(:last-child) {
  margin-right: 1rem;
}
.edit-rx-content .rx-category button .add-icon {
  font-size: 14px;
  margin-right: 0.5rem;
  font-weight: 600;
}
.edit-rx-content .rx-category .rx-category-body {
  border-bottom: 1px solid #e7e7e7;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content:not(:last-child) {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e7e7e7;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content .sub-category-header {
  display: flex;
  align-items: center;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .sub-category-title {
  color: #4e4e55;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content .sub-category-header button {
  min-height: 30px;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .sub-category-actions {
  display: flex;
  flex: 1;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .menu-link {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 0.75rem;
  margin-right: 0;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .add-component-btn {
  margin-left: auto;
}

/* Component Modal  */
.ePulse-modal-content .wrapper .vertical-tabs .tab-content {
  padding: 0 1rem;
}
.ePulse-modal-content .wrapper .vertical-tabs .tab {
  margin: 0;
}
.ePulse-modal.component-modal .ePulse-modal-content {
  padding: 0 1rem;
}
.ePulse-modal .edit-rx-container .edit-rx-category-list {
  /* overflow: visible; */
}
.ePulse-modal .edit-rx-container .edit-rx-category-list .rx-category-btn {
  position: relative;
}
/* .ePulse-modal .edit-rx-container .edit-rx-category-list .rx-category-btn.active:before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 12px solid #bdbdbd;
  border-right: 0;
  transform: translateY(-50%);
} */

.ePulse-modal .component-container {
  padding: 1rem;
  width: 100%;
  /* border: 1px solid #bdbdbd; */
  margin: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(37, 51, 66, 0.15);
}
.ePulse-modal .component-container > .row {
  height: 100%;
}
.ePulse-modal .component-container > .row > .col {
  padding: 0.5rem 0 0.5rem 1rem;
}
.ePulse-modal .component-container > .row > .col:first-child {
  border-right: 1px solid #e3e3e3;
}
.ePulse-modal .component-container .form-block {
  margin-left: 0;
}

/* Component Styles */
.component-input.form-block {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  position: relative;
}
.component-input.form-block .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 5px;
  z-index: 2;
}
.component-input {
  position: relative;
  border-radius: 5px;
  border: 1px solid #398aa6;
  flex-direction: row !important;
}
.component-input input {
  flex: 1;
  border: none;
}
.component-input .unit-label {
  min-height: calc(100% - 2px);
  min-width: 75px;
  background: #ebf5f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #398aa6;
}
.component-input.component-input-multi-separator .input-separator {
  font-size: 25px;
  opacity: 0.3;
}
.component-input.component-input-voice {
  border: none;
  background: #ebf4ff;
  /* min-height: 100px; */
}
.component-input.component-input-voice .form-label {
  /* position: relative; */
  display: none;
}
.component-input.component-input-voice .form-control {
  background: transparent !important;
  min-height: 100px;
  border: none;
}
.component-input.component-input-voice i {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background: #398aa6;
  border-radius: 50%;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.component-input.component-input-radio {
  border: none;
}
.component-input.component-input-radio .radio-group {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.component-input.component-input-checkbox {
  border: none;
}
.component-input.component-input-checkbox .checkbox-group {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.input-container {
  margin-bottom: 0.5rem;
  min-width: 30%;
  max-width: 30%;
  float: left;
}
.input-container.input-voice {
  min-width: 100%;
  max-width: unset;
}
/* Layout Component Styles */
.layout-component-parent {
  overflow: auto;
}
.layout-component-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0;
  border: 1px solid #eee;
}
.layout-component-container .component-content {
  padding: 1rem;
}
.layout-component-container h2 {
  font-size: 15px;
  background: #ebf4ff;
  padding: 1rem 0.75rem;
}
.layout-component-container .search-container {
  position: relative;
}
.layout-component-container .search-container .icon {
  width: 14px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  filter: invert(70%) sepia(8%) saturate(196%) hue-rotate(169deg) brightness(90%) contrast(90%);
}
.layout-component-container .search-container .icon-separator {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 1px;
  height: 20px;
  background-color: #9ca1a6;
  opacity: 0.6;
}
.layout-component-container .search-container input.form-control {
  color: #4f4f4f;
  background-color: #ebf4ff;
  border: none !important;
  box-shadow: none !important;
  padding-left: 40px;
}
.layout-component-container .layout-separator {
  background-color: #9ca1a6;
  opacity: 0.6;
  height: 1px;
  display: inline-block;
  width: 100%;
  margin: 1.5rem 0;
}
.layout-component-container h3 {
  font-size: 14px;
  font-weight: 500;
}
.layout-component-container .follow-up-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-component-container .follow-up-selector .form-control {
  color: #4f4f4f;
  background-color: #ebf4ff;
  border: none !important;
  box-shadow: none !important;
  max-width: 100px;
}
.layout-component-container .follow-up-selector .option-selector {
  list-style-type: none;
  display: flex;
  padding: 0;
  background: #ebf4ff;
  border-radius: 25px;
}
.layout-component-container .follow-up-selector .option-selector li {
  padding: 0.65rem 1.25rem;
  font-size: 10px;
  color: #2d313d;
  position: relative;
}
.layout-component-container .follow-up-selector .option-selector li:first-child {
  border-radius: 25px 0 0 25px;
}
.layout-component-container .follow-up-selector .option-selector li:last-child {
  border-radius: 0 25px 25px 0;
}
.layout-component-container .follow-up-selector .option-selector li.active {
  background: #398aa6;
  color: #ffffff;
  border-radius: 25px;
}
.layout-component-container .visit-date .title {
  font-weight: bold;
  margin-right: 0.5rem;
}
.component-chips {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 1rem;
}
.component-chips li {
  margin: 0 0.5rem 1rem 0;
  padding: 0.65rem 1.25rem;
  background: #ebf4ff;
  border-radius: 25px;
  font-size: 10px;
  color: #2d313d;
  position: relative;
}
.component-chips li.active {
  background: #398aa6;
  color: #ffffff;
}
.component-chips-editable li {
  padding-right: 2.5rem;
}
.component-chips-editable li .icon-separator {
  position: absolute;
  top: 9px;
  right: 26px;
  width: 1px;
  height: 15px;
  background-color: #9ca1a6;
  opacity: 0.6;
}
.component-chips-editable li .delete-icon {
  position: absolute;
  right: 0.5rem;
}
.medicine-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}
.medicine-list li {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 85%;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.medicine-list li .medical-medicine {
  font-weight: bold;
}
.medicine-list li .medical-pharmacy {
  margin-top: 0.15rem;
  font-size: 12px;
  color: #398aa6;
  font-weight: 600;
}
.medicine-list li .add-icon {
  position: absolute;
  top: 5px;
  right: 0;
  background: #ebf4ff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #398aa6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}
.medicine-list li:not(:last-child) {
  border-bottom: 1px solid rgb(156 161 166 / 60%);
}

/* Mobile / Tablet version for APP */
@media all and (max-width: 991px) {
  div.page-title {
    display: none !important;
  }
  .header-fixed .header {
    background: linear-gradient(90deg, #161717 0%, #0bbc5c 100%);
  }
  .sidebar {
    height: calc(100% - 75px);
    top: 75px;
  }
  .sidebar .toggle-icon {
    display: none;
  }
  .dashboard-landing {
    position: relative;
  }
  .dashboard-left {
    width: 100% !important;
  }
  .dashboard-landing .dashboard-counters {
    flex-wrap: wrap;
    margin-bottom: 800px;
    gap: 6px;
  }
  .dashboard-landing .dashboard-counters .count-block {
    min-width: calc(50% - 3px);
    gap: 10px;
    margin: 0 !important;
    padding: 10px;
    flex: 1 1;
    max-width: calc(50% - 3px);
  }
  .dashboard-right {
    position: absolute;
    top: 210px;
    margin: 0 !important;
  }
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .header-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .link-view-all {
    margin-left: auto;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .header-right {
    align-self: flex-end;
  }
  .dashboard-header .modalcontainer {
    width: 100%;
  }
  .dashboard-header .ePulse-datePickerParent {
    margin: 0 !important;
    justify-content: flex-start;
  }
  #kt_aside_toggle .svg-icon {
    color: #ffffff;
  }
  #kt_aside_toggle .svg-icon path {
    opacity: 1;
  }
  .dashboard-table-group {
    overflow: auto hidden;
  }
  .dashboard-table-row,
  .dashboard-table-group .dashboard-block-body {
    min-width: 600px;
  }
  .user-badge-block .user-role {
    color: #ffffff;
  }
  .wrapper hr {
    display: none;
  }
  .wrapper h2.page-title {
    margin: 15px 0;
  }
  .page-header {
    margin: 15px 0 5px !important;
  }
  .wrapper .vertical-tabs {
    flex-direction: column;
    position: relative;
    padding-top: 70px;
  }
  .wrapper .vertical-tabs .tab-list-container {
    position: absolute;
    top: 0;
    height: 62px;
    max-height: 65px;
    overflow: hidden;
    width: 100%;
    background: #fff;
  }

  .wrapper .vertical-tabs .tab-list-container.menuOpen {
    max-height: unset;
    height: 100%;
    min-height: 485px;
  }
  .wrapper .vertical-tabs .tab-list-container .tab.active {
    display: none;
  }
  .wrapper .vertical-tabs .tab-head {
    display: block;
  }
  .wrapper .vertical-tabs .tab-content .linear-tab-content .flex-row {
    flex-direction: column !important;
    margin: 5px 0;
  }
  .wrapper .vertical-tabs .tab-content .linear-tab-content .flex-row div[class*='col'] {
    width: 100%;
  }
  .filter-row {
    flex-wrap: wrap;
    position: relative;
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }
  .filter-row .filter-head {
    margin-right: 10px;
  }
  .filter-row select,
  .filter-row input {
    min-width: 100%;
  }
  .filter-row > * {
    margin: 5px 0;
  }
  .filter-row > span {
    margin: 15px 0 5px;
  }
  .filter-row > button {
    margin-top: 10px;
  }
  .filter-row span.filter-action {
    margin: 5px 0 0;
  }
  .filter-row span.filter-action + button {
    position: absolute;
    top: -52px;
    right: 0;
  }
  .admin-table {
    overflow: auto hidden;
    /* position: relative; */
    padding-bottom: 30px;
  }
  .admin-table .pagination {
    position: absolute;
    /* bottom: 0; */
    left: 20px;
  }
  .ePulse-modal {
    margin: 1rem auto;
    min-width: 75vw;
  }
  .ePulse-modal .flex-row {
    flex-direction: column !important;
  }
}
